import axios from 'axios'
import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 25000 // request timeout
})
const startLoading = () => {
  //   loading = Loading.service({
  //     lock: true,
  //     text: '加载中...',
  //     background: 'rgba(0,0,0,0.3)'
  //   })
}
const endLoading = () => {
  //   loading.close()
}

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
  // console.log('needLoadingRequestCount+', needLoadingRequestCount)
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  // console.log('needLoadingRequestCount-', needLoadingRequestCount)
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
// request interceptor
service.interceptors.request.use(
  config => {
    showFullScreenLoading()
    return config
  },
  error => {
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    tryHideFullScreenLoading();
    // if the custom code is not 20000, it is judged as an error.
    if (res.resultCode !== '000000') { // 操作成功
      if (res.resultCode === '001001') { // 未登录
        location.reload()
        return res
      }
      Message({
        message: res.message || res.resultDes || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.resultDes || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

export default service

export const postLoginRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}