import Vue from 'vue'

// 引入i18n插件
import VueI18n from 'vue-i18n'

// 引入store
// import store from '../store/index'

// 引入语言包
import enLocale from '../local/en'
import zhLocale from '../local/zh'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
}

// 配置i18n
const i18n = new VueI18n({
  locale: sessionStorage.getItem('language') || 'zh', // 从缓存中获取当前的语言类型
  messages
})

// 如果首次进入没有设置语言的话默认设置一个中文
if(!sessionStorage.getItem('language')){
  sessionStorage.setItem('language', 'zh')
}

export default i18n
