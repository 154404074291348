module.exports = {
    Header: {
        tabJson: {
            home: 'Home',
            product: 'Product',
            about: 'About',
            download: 'Download',
            help: 'Help'
        }
    },
    Foorer: {
        
    }
}