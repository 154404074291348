import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'zh', // zh: 中文，en: 英文
  },
  mutations: {
    languageFunc(state, status){
      state.language = status
    }
  },
  actions: {
    setLanguage(context, status){
      context.commit('languageFunc', status)
    }
  },
  modules: {
  }
})
