import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// const redirect = window.location.host.indexOf('CN') !== -1 ? '/qy/zh/home' : '/qy/zh/home'
// let routes = [
//   {
//     path: '/',
//     redirect: redirect
//   },
//   {
//     path: '/qy/zh',
//     name: 'zhHome',
//     component: { render: (e) => e("router-view") },
//     redirect: "/qy/zh/home",
//     children: [
//       {
//         path: "home",
//         name: "Home",
//         meta: { title: "首页" },
//         component: () => import('../views/zh/home')
//       },
//       {
//         path: "detail",
//         name: "Detail",
//         meta: { title: "产品" },
//         component: () => import('../views/zh/detail')
//       },
//       {
//         path: "about",
//         name: "About",
//         meta: { title: "关于" },
//         component: () => import('../views/zh/about')
//       },
//       {
//         path: "download",
//         name: "Download",
//         meta: { title: "下载" },
//         component: () => import('../views/zh/download')
//       },
//       {
//         path: "help",
//         name: "Help",
//         meta: { title: "帮助" },
//         component: () => import('../views/zh/help')
//       }
//     ]
//   },
//   {
//     path: '/qy/en',
//     name: 'enHome',
//     component: { render: (e) => e("router-view") },
//     redirect: "/qy/en/home",
//     children: [
//       {
//         path: "home",
//         name: "Home",
//         meta: { title: "Home" },
//         component: () => import('../views/en/home')
//       },
//       {
//         path: "detail",
//         name: "Detail",
//         meta: { title: "Products" },
//         component: () => import('../views/en/detail')
//       },
//       {
//         path: "about",
//         name: "About",
//         meta: { title: "About" },
//         component: () => import('../views/en/about')
//       },
//       {
//         path: "download",
//         name: "Download",
//         meta: { title: "Download" },
//         component: () => import('../views/en/download')
//       },
//       {
//         path: "help",
//         name: "Help",
//         meta: { title: "Help" },
//         component: () => import('../views/en/help')
//       },
//       {
//         path: "email",
//         name: "Email",
//         meta: { title: "email" },
//         component: () => import('../views/en/email')
//       }
//     ]
//   }
// ]
let routes = []
// 域名包含CN--中文网站
if(window.location.host.indexOf('cn')!==-1){
  routes = [
    {
      path: '/',
      redirect: '/qy/zh/home'
    },
    {
      path: '/qy/zh',
      name: 'zhHome',
      component: { render: (e) => e("router-view") },
      redirect: "/qy/zh/home",
      children: [
        {
          path: "home",
          name: "Home",
          meta: { title: "首页" },
          component: () => import('../views/zh/home')
        },
        {
          path: "detail",
          name: "Detail",
          meta: { title: "产品" },
          component: () => import('../views/zh/detail')
        },
        {
          path: "about",
          name: "About",
          meta: { title: "关于" },
          component: () => import('../views/zh/about')
        },
        {
          path: "download",
          name: "Download",
          meta: { title: "下载" },
          component: () => import('../views/zh/download')
        },
        {
          path: "help",
          name: "Help",
          meta: { title: "帮助" },
          component: () => import('../views/zh/help')
        }
      ]
    },
    {     
      path: '/404',       
      component: () => import('@/views/error-page/404'),       
      hidden: true     
    }, 
    {    
      path: '*',
      redirect: '/404', 
      hidden: true 
    }
  ]
}else{ //英文网站
  routes = [
    {
      path: '/',
      redirect: '/qy/en/home'
    },
    {
      path: '/qy/en',
      name: 'enHome',
      component: { render: (e) => e("router-view") },
      redirect: "/qy/en/home",
      children: [
        {
          path: "home",
          name: "Home",
          meta: { title: "Home" },
          component: () => import('../views/en/home')
        },
        {
          path: "detail",
          name: "Detail",
          meta: { title: "Products" },
          component: () => import('../views/en/detail')
        },
        {
          path: "about",
          name: "About",
          meta: { title: "About" },
          component: () => import('../views/en/about')
        },
        {
          path: "download",
          name: "Download",
          meta: { title: "Download" },
          component: () => import('../views/en/download')
        },
        {
          path: "help",
          name: "Help",
          meta: { title: "Help" },
          component: () => import('../views/en/help')
        },
        {
          path: "email",
          name: "Email",
          meta: { title: "email" },
          component: () => import('../views/en/email')
        }
      ]
    },
    {     
      path: '/404',       
      component: () => import('@/views/error-page/404'),       
      hidden: true     
    }, 
    {    
      path: '*',
      redirect: '/404', 
      hidden: true 
    }
  ]
}
const router = new VueRouter({
  routes
})

export default router
