module.exports = {
    Header: {
        tabJson: {
            home: '首页',
            product: '产品',
            about: '关于',
            download: '下载',
            help: '帮助'
        }
    },
    Foorer: {
        
    }
}